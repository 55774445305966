/**
 *  @author 付天博
 *  @time 2022/07/12
 *  @desc 网络请求封装
 */

import axios from 'axios'
import {Message} from 'element-ui'

const key = 'service'
class BaseRequest{
    constructor(config){
        this.instance = axios.create(config)
        this.showLoading = config.showLoading ?? false
        this.interceptors = config.interceptors
        this.instance.interceptors.request.use(
            this.interceptors.requestInterceptor,
            this.interceptors.requestInterceptorCatch
        )
        this.instance.interceptors.response.use(
            this.interceptors.responseInterceptor,
            this.interceptors.responseInterceptorCatch
        )
        this.instance.interceptors.request.use((config)=>{
            if (this.showLoading){
                Message({ content: '数据加载中...', key })
            }
            return config
        },(err)=>{
            return err
        })
        this.instance.interceptors.response.use((res)=>{
            return res
        },(error)=>{
            return error
        })
    }
    request(config){
        return new Promise((resolve,reject)=>{
            if (config.showLoading) {
                this.showLoading = config.showLoading
            }
            if (config.interceptors?.requestInterceptor) {
                config = config.interceptors.requestInterceptor(config)
            }
            this.instance.request(config).then(res=>{
                if (config.interceptors?.responseInterceptor) {
                    res = config.interceptors.responseInterceptor(res)
                }
                if(config.showLoading){
                    setTimeout(()=>{
                        Message({ content: '数据已加载完毕！', key, duration: 2 })
                    },500)
                }
                resolve(res)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    get(config){
        return this.request({...config,method:"GET"})
    }
    post(config){
        return this.request({...config,method:"POST"})
    }
}

export default BaseRequest