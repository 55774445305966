import Vue from 'vue'
import App from './App.vue'
import router from './router'

import service from "./utils/service"; //全局工具-网络请求
Vue.prototype.$service = service;

import "@/utils/rem.js";

// 引入ElementUI插件
import ElementUI from "element-ui";
// 引入样式
import "element-ui/lib/theme-chalk/index.css";
// 使用插件
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
