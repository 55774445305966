import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/index",
  },
  {
    path: '/index',
    component: () => import('../views/index/index.vue'),
    meta: {
      name: "人才测评详情介绍"
    },
  },
  {
    path: '/assessmentBegins',
    component: () => import('../views/assessmentBegins/assessmentBegins.vue'),
    meta: {
      name: "人才测评开始"
    },
  },
  {
    path: '/answer',
    component: () => import('../views/answer/answer.vue'),
    meta: {
      name: "答题"
    },
  },
  {
    path: '/evaluationReport',
    component: () => import('../views/evaluationReport/evaluationReport.vue'),
    meta: {
      name: "测评报告"
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
