// let BASE_URL = "";
// const TIME_OUT = 600000;
// console.log(process.env.NODE_ENV);
// if (process.env.NODE_ENV === "development") {
// 	// BASE_URL = "/dev-api";
// 	// BASE_URL = "https://rcdn.1qit.com";
// } else if (process.env.NODE_ENV === "production") {
// 	BASE_URL = "https://rcdn.1qit.com";
// } else {
// 	BASE_URL = "";
// }
// //https://spring6.cn:9344 https://rcdn.1qit.com
// export {
// 	BASE_URL,
// 	TIME_OUT
// };


let BASE_URL = ''
const TIME_OUT = 600000
BASE_URL = baseUrl 
import { baseUrl } from '../utils/baseUrl.js'

export { BASE_URL, TIME_OUT }
